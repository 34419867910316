import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './App.scss';
import AppRoutes from './AppRoutes';



class App extends Component {
  state = {}
  componentDidMount() {
    this.onRouteChanged();

   
    /*
     localStorage.removeItem('isLogined');
    localStorage.removeItem('profilInfos');
    localStorage.removeItem('isLoginedclassicuser');
    localStorage.removeItem('profilToken');
    localStorage.removeItem('statutAdmin');
    */
  }
  render () {
 
    return (
      <div className="container-scroller">
        
        <div className="container-fluid page-body-wrapper">
         
       
      
              <AppRoutes/>
         
           
     
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
   

    const body = document.querySelector('body');
    if(this.props.location.pathname === '/layout/RtlLayout') {
      body.classList.add('rtl');
    
    }
    else {
      body.classList.remove('rtl')
   
    }
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = ['/user-pages/login-1', '/user-pages/register-1', '/user-pages/lockscreen', '/error-pages/error-404', '/error-pages/error-500', '/general-pages/landing-page'];
    for ( let i = 0; i < fullPageLayoutRoutes.length; i++ ) {
      if (this.props.location.pathname === fullPageLayoutRoutes[i]) {
        this.setState({
          isFullPageLayout: true
        })
        document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
        break;
      } else {
        this.setState({
          isFullPageLayout: false
        })
        document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
      }
    }
  }

}

export default (withRouter(App));
