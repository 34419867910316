import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';

import './css.css';




import LoginGoogle from '../authentication/LoginGoogle';

class Sidebar extends Component {




  state = {
    load: false,
    isLogined: localStorage.getItem('isLogined')
  };



  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach(i => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: '/apps', state: 'appsMenuOpen' },
      { path: '/basic-ui', state: 'basicUiMenuOpen' },
      { path: '/advanced-ui', state: 'advancedUiMenuOpen' },
      { path: '/form-elements', state: 'formElementsMenuOpen' },
      { path: '/tables', state: 'tablesMenuOpen' },
      { path: '/maps', state: 'mapsMenuOpen' },
      { path: '/icons', state: 'iconsMenuOpen' },
      { path: '/charts', state: 'chartsMenuOpen' },
      { path: '/user-pages', state: 'userPagesMenuOpen' },
      { path: '/error-pages', state: 'errorPagesMenuOpen' },
      { path: '/general-pages', state: 'generalPagesMenuOpen' },
      { path: '/ecommerce', state: 'ecommercePagesMenuOpen' },
    ];

    dropdownPaths.forEach((obj => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true })
      }
    }));

  }



  render() {

    const { load } = this.state;

    let profilInfos = JSON.parse(localStorage.getItem("profilInfos"));


    const getfirstletter = () => {




      let profilInfos = JSON.parse(localStorage.getItem("profilInfos"));

      console.log("=====>")
      console.log(profilInfos);
      let prenom = profilInfos.prenom;
      let nom = profilInfos.nom;

      return prenom.slice(0, 1).toUpperCase() + "." + nom.slice(0, 1).toUpperCase();

    }





    return (
      <nav className="sidebar " id="sidebar">
        <ul className="nav">

          {/*   { (load  && profilInfos) &&  */}
          {(profilInfos) &&
            <>

              <li className="nav-item nav-profile">

                {/** HEADER MENU - HEADER MENU - HEADER MENU - HEADER MENU - HEADER MENU */}
                <a href="/admin/" >
                  <div className="brand-logo" >

                    <img alt="logo" src={`${process.env.PUBLIC_URL}/logo.png`} width="200" />

                  </div>
                </a>

                <a href="!#" className="nav-link" onClick={evt => evt.preventDefault()}>

                  <div style={{
                    backgroundColor: "grey", width: 44, height: 44, borderRadius: 44,
                    paddingTop: 13,

                  }} >
                    <a style={{
                      color: "#fff",
                      textAlign: "center", fontSize: 19, paddingLeft: 7,
                    }}>

                      {getfirstletter()}
                    </a>
                  </div>
                  <div className="nav-profile-text">
                    <span className="font-weight-bold mb-2">{ }</span>


                    <div>
                      {profilInfos.prenom}{" "}{profilInfos.nom}

                    </div>


                  </div>

                </a>
              </li>

            </>
          }



          {/*



          email: "demotestns@infa-formation.com"
          familyName: "SIRIUS"
          givenName: "Nicolas"
          googleId: "112700105769691624477"
          imageUrl: "https://lh3.googleusercontent.com/a/AATXAJyCFhf1LDAfWGGP6FVq50ZIxiAfvWUE3WOzgQGD=s96-c"
          name: "Nicolas SIRIUS"

          */}



          {/** ELEMENTS MENU - ELEMENTS MENU - ELEMENTS MENU */}





          <li className={this.isPathActive('/admin/pages') ? 'nav-item active' : 'nav-item'}

          >
            <Link className="nav-link" to="/admin/pages"
              style={{
                paddingTop: 9,
                paddingBottom: 9
              }}
            >
              <i className="mdi mdi-note-text menu-icon"></i>
              <span className="menu-title">Pages</span>

            </Link>
          </li>




          <li className={this.isPathActive('/admin/articles') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/admin/articles"
              style={{
                paddingTop: 9,
                paddingBottom: 9
              }}
            >
              <i className="mdi mdi-newspaper menu-icon"></i>
              <span className="menu-title">Articles</span>

            </Link>
          </li>




          <li className={this.isPathActive('/admin/offres') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/admin/offres"
              style={{
                paddingTop: 9,
                paddingBottom: 9
              }}
            >
              <i className="mdi mdi-briefcase menu-icon"></i>
              <span className="menu-title">Offres emplois</span>

            </Link>
          </li>




          <li className={this.isPathActive('/admin/entreprises') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/admin/entreprises"
              style={{
                paddingTop: 9,
                paddingBottom: 9
              }}
            >
              <i className="mdi mdi-office-building menu-icon"></i>
              <span className="menu-title">Entreprises</span>

            </Link>
          </li>



          {/*
              
            
            
          <li className={this.isPathActive('/admin/evenements') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/admin/evenements"
              style={{
                paddingTop: 9,
                paddingBottom: 9
              }}
            >
              <i className="mdi mdi-calendar menu-icon"></i>
              <span className="menu-title">Evènements</span>

            </Link>
          </li>

            */        }

          <li className={this.isPathActive('/admin/cvtheques') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/admin/cvtheques"
              style={{
                paddingTop: 9,
                paddingBottom: 9
              }}
            >
              <i className="mdi mdi-file menu-icon"></i>
              <span className="menu-title">Cvthèques</span>
            </Link>
          </li>




          <li className={this.isPathActive('/admin/media') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/admin/media"
              style={{
                paddingTop: 9,
                paddingBottom: 9
              }}
            >
              <i className="mdi mdi-folder-multiple-image menu-icon"></i>
              <span className="menu-title">Média</span>

            </Link>
          </li>


          <li>
            <hr style={{ backgroundColor: "" }} />
          </li>


          <li className={this.isPathActive('/admin/menu') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/admin/menu"
              style={{
                paddingTop: 9,
                paddingBottom: 9
              }}
            >
              <i className="mdi mdi-menu menu-icon"></i>
              <span className="menu-title">Menu Principal</span>

            </Link>
          </li>
          <li className={this.isPathActive('/admin/publipostages') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/admin/publipostages"
              style={{
                paddingTop: 9,
                paddingBottom: 9
              }}
            >
              <i className="mdi mdi-email menu-icon"></i>
              <span className="menu-title">Publipostage</span>

            </Link>
          </li>






          <li className={this.isPathActive('/admin/diplomes') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/admin/diplomes"
              style={{
                paddingTop: 9,
                paddingBottom: 9
              }}
            >
              <i className="mdi mdi-school menu-icon"></i>
              <span className="menu-title">Diplômes</span>

            </Link>
          </li>


          <li className={this.isPathActive('/admin/utilisateurs') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/admin/utilisateurs"
              style={{
                paddingTop: 9,
                paddingBottom: 9
              }}
            >
              <i className="mdi mdi-account menu-icon"></i>
              <span className="menu-title">Utilisateurs</span>

            </Link>
          </li>

          <li className={this.isPathActive('/admin/administrateurs') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/admin/administrateurs"
              style={{
                paddingTop: 9,
                paddingBottom: 9
              }}
            >
              <i className="mdi mdi-account-key menu-icon"></i>
              <span className="menu-title">Administrateurs</span>

            </Link>
          </li>

          {/*
          
           <li className={ this.isPathActive('/admin/groupes') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/admin/groupes">
            <i className="mdi mdi-account-card-details menu-icon"></i>
              <span className="menu-title">Groupes</span>
            
            </Link>
          </li>
        
          */}

          {/*
          
        
          <li className={ this.isPathActive('/admin/parametres') ? 'nav-item active' : 'nav-item' }>
              <Link className="nav-link" to="/admin/parametres">
              <i className="mdi mdi-settings menu-icon"></i>
                <span className="menu-title">Parametres du site</span>
          
              </Link>
            </li>
          
          */}

          <li className={this.isPathActive('/admin/reglages') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/admin/reglages"
              style={{
                paddingTop: 9,
                paddingBottom: 9
              }}
            >
              <i className="mdi mdi-view-day menu-icon"></i>
              <span className="menu-title">Reglages</span>

            </Link>
          </li>

          <li className={'nav-item'}>
            <Link className="nav-link" to="/site"

              style={{
                paddingTop: 9,
                paddingBottom: 9
              }}
            >
              <i className="mdi mdi-web menu-icon"></i>
              <span className="menu-title">Aller sur le site </span>

            </Link>
          </li>


          <li className="nav-item">
            <Link className="nav-link">
              <i className="mdi mdi-logout menu-icon"></i>
              <li><center><LoginGoogle /></center></li>
            </Link>

          </li>


        </ul>
      </nav>
    );



  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {







    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {

      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });

    this.setState({
      load: true,
    })


  }

}

export default withRouter(Sidebar);