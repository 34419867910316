import React, { Component, Suspense, lazy, useEffect, useState } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import Spinner from '../app/shared/Spinner';
import Sidebar from './shared/Sidebar';


const Reglages = lazy(() => import('./reglages/Reglages'));
const Menu = lazy(() => import('./menu/Menu'));
const User = lazy(() => import('./user/User'));
const Media = lazy(() => import('./media/Media'));
const Webpage = lazy(() => import('./webpage/Webpage'));

const Offres = lazy(() => import('./offres/Offres'));
const PageOffre = lazy(() => import('./offres/PageOffre'));

const Dashboard = lazy(() => import('./dashboard/Dashboard'));

const Articles = lazy(() => import('./articles/Articles'));
const PageArticle = lazy(() => import('./articles/PageArticle'));


const Publipostage = lazy(() => import('./publipostage/Publipostage'));
const PagePublipostage = lazy(() => import('./publipostage/PagePublipostage'));



const Evenements = lazy(() => import('./evenements/Evenements'));
const PageEvenement = lazy(() => import('./evenements/PageEvenement'));


const Cvtheques = lazy(() => import('./cvtheque/Cvtheques'));
const PageCvtheques = lazy(() => import('./cvtheque/PageCvtheques'));



const Entreprises = lazy(() => import('./entreprises/Entreprises'));
const PageEntreprise = lazy(() => import('./entreprises/PageEntreprise'));

const Diplomes = lazy(() => import('./diplomes/Diplomes'));
const PageDiplome = lazy(() => import('./diplomes/PageDiplome'));


const Administrateurs = lazy(() => import('./administrateurs/Administrateurs'));
const Utilisateurs = lazy(() => import('./utilisateurs/Utilisateurs'));
const PageUtilisateur = lazy(() => import('./utilisateurs/PageUtilisateur'));

const Groupes = lazy(() => import('./groupes/Groupes'));
const Parametres = lazy(() => import('./parametres/Parametres'));

const Site = lazy(() => import('./editeur/Site'));

const Editeur = lazy(() => import('./editeur/Editeur'));


const Login = lazy(() => import('./user-pages/Login'));






//*ROUTES DE L'APPLICATION
const AppRoutes = (props) => {


  const [isLogined, setIsLogined] = useState(null);
  const [load, setLoad] = useState(false);

  const location = useLocation();

  //*function : pour connaitre le path
  function isPathActive(path) {


    //return <h1>Path is: {location.pathname}</h1>;


    return location.pathname.startsWith(path);
  }

  useEffect(() => {



    //*ON CHECK SI L'UTILISATEUR EST CONNECTÉ OU NON 
    let isLogined = false;
    if (localStorage.getItem("isLogined")) {
      isLogined = true;
    }

    setIsLogined(isLogined);
    setLoad(true);


  }, []);


  if (load) {


    //*SI L'UTILISATEUR N'EST PAS CONNECTÉ
    if (!isLogined) {
      return (

        <Suspense fallback={<Spinner />}>
          <Switch>

            <div className="main-panel" style={{
              marginLeft: 260,
            }}>
              <div style={{ marginLeft: -260, }}>

                <Route path="/" component={Site} />


              </div>


            </div>


            {/**ON REDIRIGE AU DEPART SUR LE SITE****/}
            <Redirect to="/site" />
          </Switch>

        </Suspense>
      )

    } else { //*SI L'UTILISATEUR EST CONNECTE

      return (



        <Suspense fallback={<Spinner />}>

          <Switch>




            <div className="main-panel" style={{
              marginLeft: 260,
            }}>


              {/**REDIRECTION DE LOGIN VERS LE SITE , la redirection se fais dans le component*/}
              <Route path="/login" component={Site} />



              {/***ROUTES RESERVES UNIQUEMENT POUR LES ADMINISTRATEURS*/}
              {(localStorage.getItem("statutAdmin") === "true") &&
                <>

                  {/**ON METS LA SIDEBAR UNIQUEMENT POUR LA PARTIE ADMIN*/}
                  {isPathActive("/admin") &&
                    <Sidebar />
                  }

                  <Route exact path="/admin" component={Dashboard} /> {/**ON REDIRIGE LA PAGE ADMIN SUR LES PAGES WEB */}
                  <Route exact path="/admin/menu" component={Menu} />
                  <Route exact path="/admin/reglages" component={Reglages} />

                  <Route exact path="/admin/user" component={User} />
                  <Route exact path="/admin/media" component={Media} />
                  <Route exact path="/admin/pages" component={Webpage} />

                  <Route exact path="/admin/offres" component={Offres} />
                  <Route exact path="/admin/offre*" component={PageOffre} />


                  <Route exact path="/admin/articles" component={Articles} />
                  <Route exact path="/admin/article*" component={PageArticle} />


                  <Route exact path="/admin/evenements" component={Evenements} />
                  <Route exact path="/admin/evenement*" component={PageEvenement} />


                  <Route exact path="/admin/cvtheques" component={Cvtheques} />
                  <Route exact path="/admin/cvtheques*" component={PageCvtheques} />


                  <Route exact path="/admin/entreprises" component={Entreprises} />
                  <Route exact path="/admin/entreprise*" component={PageEntreprise} />


                  <Route exact path="/admin/diplomes" component={Diplomes} />
                  <Route exact path="/admin/diplome*" component={PageDiplome} />


                  <Route exact path="/admin/publipostages" component={Publipostage} />
                  <Route exact path="/admin/publipostage*" component={PagePublipostage} />

                  <Route exact path="/admin/parametres" component={Parametres} />
                  <Route exact path="/admin/administrateurs" component={Administrateurs} />
                  <Route exact path="/admin/utilisateurs" component={Utilisateurs} />
                  <Route exact path="/admin/utilisateur*" component={PageUtilisateur} />


                  <Route exact path="/admin/groupes" component={Groupes} />


                </>
              }


              <div style={{ marginLeft: -260, }}>
                {/**POUR TOUT LES MEMBRES, MAIS VERIFICATION DES DROITS DANS LE COMPONENT *****/}
                <Route exact path="/admin/editeur*" component={Editeur} />
                {/**POUR TOUT LES MEMBRES, MAIS VERIFICATION DES DROITS DANS LE COMPONENT*****/}
                <Route exact path="/site*" component={Site} />

                {/**ON REDIRIGE PAR DEFAUT SUR LE SITE   */}


                <Route exact path="/" component={Site} />



              </div>


            </div>






            {/**ON REDIRIGE AU DEPART SUR LE SITE****/}
            <Redirect to="/site" />
          </Switch>
        </Suspense>

      );
    }
  } else {
    return (
      <div>

      </div>
    )
  }

}

export default AppRoutes;